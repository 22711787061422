<template>
  <div class="container">
    <!-- logo -->
    <img class="ronge-logo" src="../assets/images/rongE_logo.png" />

    <!-- 注册成功 顶部提示语 金额 按钮 -->
    <div class="u-flex-col top">
      <div class="u-flex u-row-center u-col-center">
        <img class="check-mark" src="../assets/images/icon_check_mark.png" />
        <span>注册成功，获得初审额度</span>
      </div>

      <count-to
        :start-val="0"
        :end-val="200000"
        :duration="3600"
        class="amount u-text-center"
      />

      <button class="btn-submit" @click="handleSubmit">前往APP下载</button>

      <span class="tips" v-if="isFinsh">今日产品推荐</span>
    </div>

    <!-- 列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <template v-if="isFinsh">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="product-item"
            @click="handleGoLink(item)"
          >
            <div class="u-flex u-row-between u-col-bottom">
              <div class="u-flex u-col-center">
                <img class="product-logo" :src="item.logo" />
                <span class="product-title">{{ item.productName }}</span>
              </div>
              <span class="servicer">已服务{{ item.loanCount }}人</span>
            </div>

            <div class="product-item-middle u-flex u-row-between u-col-center">
              <div class="product-item-middle-left">
                <count-to
                  :start-val="0"
                  :end-val="item.loanAmount"
                  :duration="3600"
                  class="number-span"
                />
                <small>元</small>
                <h5 class="product-tips">最高可借金额</h5>
              </div>
              <div class="product-item-middle-center">
                <span class="number-span">
                  {{ item.loanPeriod }}<small>月</small>
                </span>
                <h5 class="product-tips">借款期限</h5>
              </div>
              <div class="">
                <span class="number-span">
                  {{ item.loanRate }}<small>%</small>
                </span>
                <h5 class="product-tips">综合年化</h5>
              </div>
            </div>

            <div class="u-flex u-row-between u-col-center">
              <div class="u-flex u-col-center">
                <van-tag
                  v-for="(i, idx) in item.productTagList"
                  :key="idx"
                  color="#FFF4E6"
                  text-color="#FD9A15"
                >
                  {{ i }}
                </van-tag>
              </div>
              <button class="prodact-btn">去申请</button>
            </div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import SharePop from "@/components/SharePop";
import * as API_Common from "@/api/common.js";

export default {
  name: "ProductRecomed",
  components: {
    CountTo,
    SharePop,
  },
  data() {
    return {
      channel: this.$route.query.source,
      isFinsh: false,
      list: [],
      params: {
        pageNum: 1,
        pageSize: 5,
      },
      loading: false,
      finished: false,
      refreshing: true,
      total: 0,
      downAppUrl: "",
      showPop: false,
    };
  },
  created() {
    this.getDownloadUrl();
  },
  methods: {
    handleSubmit() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        window.location.href = this.downAppUrl;
      }
    },

    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        }
      });
    },

    getProductList() {
      API_Common.queryProductList(this.params).then((res) => {
        if (200 === res.code) {
          if (res.data.list.length > 0) {
            this.total = res.data.total;
            this.params.pageNum++;

            res.data.list.map((item) => {
              item.loanAmount = Number(item.loanAmount);
            });

            this.list.push(...res.data.list);
            this.loading = false;
            if (this.list.length >= this.total) {
              this.finished = true;
            }
            this.isFinsh = true;
          } else {
            this.finished = true;
            this.isFinsh = false;
          }
        } else {
          this.$toast.fail("获取产品列表失败");
        }
      });
    },

    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
        this.params.pageNum = 1;
      }
      this.getProductList();
    },

    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    handlePopChange(show) {
      this.showPop = show;
    },
    //埋点
    handleGoLink(item) {
      API_Common.buried({
        productId: item.productId,
        showPage: 5,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(function () {
            window.location.href = item.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0.28rem 0.4rem 0.2rem;
  height: 100vh;
  background: url("../assets/images/product_recomed_bg.png") no-repeat center
    center;
  background-size: 100%;
  background-color: #fa7f20;
  overflow: auto;
}

.ronge-logo {
  width: 1.92rem;
  vertical-align: top;
}

.top {
  margin: -0.35rem 0 0.24rem;
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
}

.check-mark {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.16rem;
}

.amount {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.32rem;
}

.btn-submit {
  width: 4rem;
  height: 0.96rem;
  background: linear-gradient(#ff8322, #ff4a0d);
  border-radius: 0.48rem;
  display: block;
  font-size: 0.36rem;
  font-weight: 500;
  color: #ffffff;
  margin: 0.2rem auto;
}

.tips {
  margin: 0.35rem auto 0;
  font-size: 0.28rem;
}

.product-item {
  background: #ffffff;
  border-radius: 0.16rem;
  padding: 0.32rem 0.28rem 0.3rem 0.3rem;
  margin-bottom: 0.24rem;
}

.product-logo {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.12rem;
  border-radius: 50%;
}

.product-title {
  font-size: 0.28rem;
  font-weight: 600;
  color: #3a3a3a;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.servicer {
  word-break: keep-all;
  font-size: 0.24rem;
  color: #ffbb31;
}

.product-item-middle {
  margin: -0.27rem 0 0.24rem;
}

.product-item-middle-left {
  color: #fb8d0b;
}

.product-item-middle small {
  font-size: 0.24rem;
}

.number-span {
  font-size: 0.48rem;
  font-weight: 600;
  margin-right: -0.13rem;
  word-break: keep-all;
}

.product-item-middle-center {
  color: #3a3a3a;
}

.product-item-middle-left .product-tips {
  margin-top: -0.15rem;
}

.product-tips {
  font-size: 0.24rem;
  color: #c3c2c6;
  font-weight: 400;
}

.prodact-btn {
  width: 1.44rem;
  height: 0.6rem;
  background: linear-gradient(90deg, #ffbb31, #fb8302);
  border-radius: 0.36rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #ffffff;
}

.van-tag {
  margin-right: 0.1rem;
}

::v-deep {
  .van-pull-refresh__head,
  .van-list__loading,
  .van-loading__text,
  .van-loading__spinner,
  .van-list__finished-text {
    font-size: 0.28rem;
    color: #fff !important;
  }
}
</style>
